import { useEventListener, useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useEffect, useState } from 'react'
import scrollToElement from '../../helpers/scroll-to-element'

export const ScrollDown = ({
  text = 'Scroll Down',
  target,
  relativeTo,
  video,
}) => {
  const { isInViewport, setRef } = useIsInViewport(false)
  const [hidden, setHidden] = useState(false)

  const smoothScroll = useCallback(() => {
    const targetElement = document.querySelector(target)

    if (targetElement) {
      scrollToElement(targetElement)
    }
  }, [target])

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      setHidden(window.scrollY > 10)
    })
  }, [setHidden])

  useEventListener(
    'scroll',
    handleScroll,
    { passive: true },
    typeof window !== 'undefined' ? window : undefined,
    isInViewport,
  )

  return (
    <div
      className={`scroll-down__wrapper ${video ? 'video' : ''}`}
      aria-hidden={hidden}
      ref={setRef}
    >
      <button
        className="button-swoop scroll-down"
        ref={handleScroll}
        id="scroll-down"
        onClick={smoothScroll}
        data-title={text}
      >
        {text}
      </button>
    </div>
  )
}

export default ScrollDown
