import PropTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { TextColourContext } from '../context/text-colour-context-provider'
import { TermsConditionsPageLink } from './navigation/terms-conditions-page-link'
import { Time } from './footer/time'
import { animate } from '../helpers/animator'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ContactPageLink } from './navigation/contact-page-link'
import Newsletter from './newsletter'

const Footer = ({ siteTitle }) => {
  const queryData = useStaticQuery(graphql`
    query FooterImage {
      prismicFooter {
        data {
          instagram_link {
            url
          }
          instagram_name
          profile_image {
            url
          }
        }
      }
    }
  `)

  const [position, setPosition] = useState({
    left: '0',
    top: '0',
    opacity: '0',
    transform: 'scale(0)',
    display: 'none',
  })
  const [imageClass, setImageClass] = useState('instagram-profile hidden')

  const { textColour } = useContext(TextColourContext)

  const toggleImage = useCallback(
    event => {
      requestAnimationFrame(() => {
        setPosition(position => ({
          ...position,
          left: `${event.clientX}px`,
          top: `${event.clientY}px`,
          opacity: '1',
          transform: 'scale(1)',
          display: 'block',
        }))
      })
      setImageClass('instagram-profile visible')
    },
    [setPosition, setImageClass],
  )

  const hideImage = useCallback(() => {
    requestAnimationFrame(() => {
      setPosition(position => ({
        ...position,
        opacity: '0',
        transform: 'scale(0)',
        display: 'none',
      }))
    })
    setImageClass('instagram-profile hidden')
  }, [setPosition, setImageClass])

  const profileImage = queryData.prismicFooter?.data?.profile_image?.url
  const instagramName = queryData.prismicFooter?.data?.instagram_name
  const instagramLink = queryData.prismicFooter?.data?.instagram_link?.url
  const instaUrl = queryData.prismicFooter.data.instagram_link.url

  return (
    <footer className={`footer footer--${textColour}`} ref={animate}>
      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__column mobile-contact">
            <ContactPageLink />
          </div>
          {instaUrl && (
            <div
              className={imageClass}
              style={{
                left: `${position.left}`,
                top: `${position.top}`,
              }}
            >
              <img src={profileImage} />
              <svg
                style={{
                  display: `${position.display}`,
                }}
                width="103"
                height="103"
                viewBox="0 0 103 103"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="51.5"
                  cy="51.5"
                  r="49.75"
                  stroke="url(#paint0_linear_140_923)"
                  strokeWidth="3.5"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_140_923"
                    x1="87"
                    y1="13"
                    x2="19"
                    y2="94"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.224477" stopColor="#B63B8D" />
                    <stop offset="1" stopColor="#ECA05C" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}

          <Newsletter />

          <div className="footer__column footer-instagram">
            <div className="footer__instagram">
              <span className="footer__instagram-label">
                Follow on Instagram
              </span>
              <a
                href={instagramLink}
                target="_blank"
                rel="noopener noreferrer"
                className="footer__instagram-handle"
                onMouseEnter={toggleImage}
                onMouseMove={toggleImage}
                onMouseOut={hideImage}
                onTouchEnd={hideImage}
              >
                {instagramName}
              </a>
            </div>
          </div>

          <div className="footer__column mobile-back-to-top">
            <a href="#">Back to top</a>
          </div>
        </div>

        <div className="footer__bottom">
          <span>
            London, UK. <Time />
          </span>

          <div>
            <span className="footer__copyright">
              <span className="screenreader-text">Copyright </span>
              {new Date().getFullYear()} {siteTitle}
            </span>

            <span className="footer__legal">
              - all rights reserved{' '}
              <TermsConditionsPageLink displayText="Legal" />
            </span>
          </div>

          <div className="footer__credit-info">
            Site by{' '}
            <a
              href="https://www.superrb.com"
              rel="nofollow noopener"
              target="_blank"
              title="Website by Superrb"
            >
              Superrb
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
