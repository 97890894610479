import React, { Component } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'

export const LegalPageNav = ({ data }) => {
  const queryData = useStaticQuery(graphql`query LegalpageNavigationQuery {
  allPrismicLegalpage(sort: {last_publication_date: ASC}) {
    nodes {
      data {
        meta_navigation_title
      }
      uid
      type
    }
  }
}`)

  return (
    <nav className="legal-nav">
      <ul>
        {queryData.allPrismicLegalpage.nodes.map((legalPage, index) => {
          const url = linkResolver(legalPage)

          return (
            <li className="nav__list-item legal-nav__list-item" key={index}>
              <Link
                to={url}
                className="nav__link"
                activeClassName="nav__link--active legal-nav__list-item__active"
              >
                {legalPage.data.meta_navigation_title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
