import { useLocation } from '@reach/router'
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useFormFields, useMailChimpForm } from '../hooks/use-mailchimp-form'
import { RequiredMessage } from './contact/RequiredMessage'

const Newsletter = () => {
  const location = useLocation()
  const label = useRef(false)
  const emailInput = useRef(null)
  const [errors, setErrors] = useState(false)

  const url =
    'https://rhysframpton.us7.list-manage.com/subscribe/post?u=552e2f2bf605c6bfc39165fad&amp;id=f631eac92c'
  const { loading, error, success, message, handleSubmit, reset } =
    useMailChimpForm(url)
  const { fields, setValues, handleFieldChange } = useFormFields({
    EMAIL: '',
  })

  const onSubmit = useCallback(
    fields => {
      if (fields.EMAIL.length == 0) {
        setErrors(true)
      } else {
        setErrors(false)
        handleSubmit(fields)
      }
    },
    [handleSubmit, setErrors],
  )

  useEffect(() => {
    reset()
    setValues({
      EMAIL: '',
    })
  }, [location])

  const checkLengthForLabels = useCallback(
    event => {
      handleFieldChange(event)
      if (event.target.value.length > 0) {
        label.current.className = 'active'
      } else {
        label.current.className = ''
      }
    },
    [handleFieldChange],
  )

  const moveLabel = useCallback(event => {
    label.current.className = 'active'
  }, [])

  return (
    <div className="footer__column footer-newsletter">
      <p className="footer__newsletter-text">
        Join our newsletter{' '}
        <span className="footer__newsletter-text__small">
          (No spam I promise ✌️)
        </span>
      </p>
      <div className={`footer__newsletter ${errors && 'error'}`}>
        {!(error || success) && (
          <form
            onSubmit={event => {
              event.preventDefault()
              onSubmit(fields, event)
            }}
          >
            <label htmlFor="footer_email" ref={label}>
              Enter your email
            </label>
            <input
              ref={emailInput}
              id="EMAIL"
              name="footer_email"
              type="email"
              value={fields.EMAIL}
              onChange={handleFieldChange}
              onFocus={moveLabel}
              onBlur={checkLengthForLabels}
            />
            <button disabled={loading}>Sign Up</button>
            {errors && <RequiredMessage />}
          </form>
        )}
        <div
          className={`footer__message ${error && 'error'} ${
            success && 'success'
          }`}
        >
          {error ? 'Sorry, an error occurred.' : message}
        </div>
      </div>
    </div>
  )
}

export default Newsletter
