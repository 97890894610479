import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import React, { useContext, useEffect } from 'react'
import { ProjectBanner } from '../components/projects/banner'
import { ProjectContent } from '../components/projects/content'
import { ProjectContentNavbar } from '../components/projects/content-navbar'
import { RelatedProjects } from '../components/projects/related'
import { Seo } from '../components/seo'
import { TextColourContext } from '../context/text-colour-context-provider'
import useParallax from '../hooks/use-parallax'

export const query = graphql`
  query ProjectQuery($id: String) {
    prismicProject(id: { eq: $id }) {
      ...Project
    }
  }
`

const Project = ({ data }) => {
  const { setTextColour: setFooterColour } = useContext(TextColourContext)
  useEffect(() => {
    if (![true, false].includes(data?.prismicProject?.data?.text_colour)) {
      return
    }

    setFooterColour(data.prismicProject.data.text_colour ? 'light' : 'dark')
  }, [setFooterColour, data])

  if (!data) return null

  /**
   * @type {Project}
   */
  const pageData = data.prismicProject.data
  useParallax(
    '.item--small .project-content__media-item-inner, .item--medium .project-content__media-item-inner',
    0.5,
  )

  return (
    <>
      <Seo data={pageData} />
      <ProjectBanner
        title={pageData.page_title}
        subtitle={pageData.subtitle}
        mainImage={pageData.main_image}
        portraitImage={pageData.portrait_image}
        colour={pageData.text_colour}
        autoPlayVideo={pageData?.autoplay_video}
      />
      <ProjectContent
        title={pageData.page_title}
        description={pageData.description}
        content={pageData.content}
        backgroundColour={pageData.background_colour}
        colour={pageData.text_colour}
      />
      <ProjectContentNavbar />
      <RelatedProjects data={pageData.related} />
    </>
  )
}
export default withPrismicPreview(Project)
