import React, { createContext, useCallback, useState } from 'react'

export const NavContext = createContext({
  navOpen: false,
  toggleNav: () => {},
  closeNav: () => {},
})

export const NavContextProvider = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false)

  const toggleNav = useCallback(() => {
    setNavOpen(navOpen => !navOpen)
  }, [setNavOpen])

  const closeNav = useCallback(() => {
    const link = document.activeElement
    if (link.closest('.nav') && 'blur' in link) {
      link.blur()
    }
    setNavOpen(false)
  }, [setNavOpen])

  return (
    <NavContext.Provider value={{ navOpen, toggleNav, closeNav }}>
      {children}
    </NavContext.Provider>
  )
}

export default NavContextProvider
