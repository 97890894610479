import * as React from 'react'
import { graphql, StaticQuery, useStaticQuery } from 'gatsby'

import Footer from './footer'
import Header from './header'
import { CookieBanner } from './cookie-banner'
import PreloadLinks from "@superrb/gatsby-addons/src/components/preload-links";

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PreloadLinks />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
      <CookieBanner />
    </>
  )
}
