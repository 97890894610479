import React, { Component } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'

export const ProjectsPageLink = ({ displayText, ...props }) => {
  const queryData = useStaticQuery(graphql`
    query ProjectsPageQuery {
      prismicHomepage {
        data {
          meta_navigation_title
        }
        url
        type
        id
      }
    }
  `)

  const pageData = queryData.prismicHomepage
  const url = linkResolver(pageData)

  return (
    <Link to={url} {...props}>
      {displayText ? displayText : pageData.data.meta_navigation_title}
    </Link>
  )
}
