import {
  useEventListener,
  useIsInViewport,
  useIsMobile,
} from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

const VideoAutoplayBanner = url => {
  const player = useRef()
  const isMobile = useIsMobile()
  const [isPortrait, setIsPortrait] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [isOverlayVisible, setIsOverlayVisible] = useState(true)
  const [hasSound, setHasSound] = useState(false)
  const [volume, setVolume] = useState(false)
  const [screenSize, setScreenSize] = useState({ width: 1024, height: 768 })

  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    const restart = setInterval(() => {
      if (player.current) {
        player.current.seekTo(0)
      }
    }, 3000)

    return () => {
      clearInterval(restart)
    }
  }, [player])

  const handleResize = useCallback(() => {
    setIsPortrait(window.matchMedia('(orientation: portrait)').matches)
  }, [setIsPortrait])

  useEffect(() => {
    setIsPortrait(window.matchMedia('(orientation: portrait)').matches)
  }, [])

  useEventListener(
    'resize',
    handleResize,
    {},
    typeof window !== 'undefined' ? window : undefined,
  )

  useEffect(() => {
    setScreenSize({ height: window.innerHeight, width: window.innerWidth })
  }, [setScreenSize])

  return (
    <div ref={setRef} className="banner__video ">
      <ReactPlayer
        url={url.url}
        autoPlay={true}
        light={false}
        controls={false}
        playing={isInViewport}
        muted={true}
        loop={true}
        playsinline
        playsInline
        ref={player}
        className="react-player"
        width={`${isPortrait ? screenSize.height * 2 : screenSize.width}px`}
        height={
          isMobile
            ? `${isPortrait ? screenSize.height : screenSize.width * 2}px`
            : '100vh'
        }
        config={{
          vimeo: {
            playerOptions: {
              responsive: true,
              width: isPortrait
                ? `${screenSize.height * 2}px`
                : `${screenSize.width}px`,
              height: isPortrait
                ? `${screenSize.height}px`
                : `${screenSize.width * 2}px`,
            },
          },
        }}
      />
    </div>
  )
}

export default VideoAutoplayBanner
