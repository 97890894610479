import React, { useContext, useEffect } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { Listing } from '../components/projects/listing'
import { Filter } from '../components/projects/filter'

import Homepage from '../types/pages/homepage'
import Project from '../types/pages/project'
import VideoOverlay from '../components/video-overlay'
import Roundel from '../components/roundel'
import { TextColourContext } from '../context/text-colour-context-provider'

export const query = graphql`query HomepageQuery {
  prismicHomepage {
    ...Homepage
  }
  projects: allPrismicProject(sort: {data: {sort_date: DESC}}) {
    nodes {
      ...Project
    }
  }
}`

const Index = ({ data }) => {
  const { setTextColour } = useContext(TextColourContext)
  useEffect(() => {
    setTextColour('light')
  }, [setTextColour])

  if (!data) return null

  const pageData = data.prismicHomepage.data

  /**
   * @type {Project[]}
   */
  const projects = data.projects.nodes

  return (
    <>
      <Seo data={pageData} />
      {pageData.video_url?.url && <VideoOverlay url={pageData.video_url.url} />}
      <Filter />
      <Roundel />
      <Listing projects={projects} />
    </>
  )
}
export default withPrismicPreview(Index)
