import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { RichText } from '@superrb/gatsby-addons/components'
import { LegalPageNav } from '../components/navigation/legal-page-nav'
import { CustomLink } from '../utils/custom-link'

export const query = graphql`
  query LegalPageQuery($id: String) {
    prismicLegalpage(id: { eq: $id }) {
      _previewable
      id
      uid
      data {
        page_title
        page_content {
          richText
          text
          html
        }
        meta_description
        meta_navigation_title
        meta_robots
        meta_title
        meta_image {
          fluid {
            base64
            src
          }
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const Legal = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicLegalpage.data

  return (
    <>
      <Seo data={pageData} />
      <LegalPageNav />
      <div className="legal__content">
        <span className="legal__title">
          <h1>{pageData.page_title}</h1>
        </span>
        <RichText field={pageData.page_content.richText} />
      </div>
    </>
  )
}
export default withPrismicPreview(Legal)
