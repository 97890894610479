import React, { useCallback, useContext, useEffect } from 'react'
import { animate } from '../../helpers/animator'
import { ProjectsPageLink } from '../navigation/projects-page-link'
import { TextColourContext } from '../../context/text-colour-context-provider'

export const ProjectContentNavbar = () => {
  const url = typeof window !== 'undefined' ? window.location.href : ''

  const { textColour } = useContext(TextColourContext)

  return (
    <div
      className={`project-navbar ${
        textColour === 'dark'
          ? 'project-navbar--white'
          : 'project-navbar--orange'
      }`}
      ref={animate}
    >
      <div className="project-navbar__backto">
        <ProjectsPageLink
          displayText={'Back to projects'}
          className="project-navbar__backto-link"
        />
      </div>
      <div className="project-navbar__socials">
        <p>
          Share <span>on socials</span>
        </p>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          className="project-navbar__social"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}`}
          className="project-navbar__social"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          href={`http://pinterest.com/pin/create/link/?url=${url}`}
          className="project-navbar__social"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pinterest
        </a>
      </div>
    </div>
  )
}

export default ProjectContentNavbar
