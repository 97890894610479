import React, { Component } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'

export const ContactPageLink = ({ displayText }) => {
  const queryData = useStaticQuery(graphql`
    query ContactPageQuery {
      prismicContact {
        data {
          meta_navigation_title
        }
        url
        type
        id
      }
    }
  `)

  const pageData = queryData.prismicContact
  const url = linkResolver(pageData)

  return (
    <Link to={url}>
      {displayText ? displayText : pageData.data.meta_navigation_title}
    </Link>
  )
}
