import React, { useEffect } from 'react'
import { NewsArticle } from './news-article'
import useWindowDimensions from '../hooks/use-window-dimensions'
// import useScaling from '../hooks/use-scaling'

export const NewsArticles = ({ articles }) => {
  // useScaling('.news-articles__article__inner')
  const { height, width } = useWindowDimensions()

  if (width < 720) {
    return (
      <section className="news-articles">
        {articles.map((article, index) => {
          return <NewsArticle article={article} key={index} index={index} />
        })}
      </section>
    )
  } else {
    return (
      <section className="news-articles">
        <div className="news-articles__left">
          {articles.map((article, index) => {
            if (index % 2 === 0) {
              return <NewsArticle article={article} key={index} index={index} />
            }
          })}
        </div>
        <div className="news-articles__right">
          {articles.map((article, index) => {
            if (index % 2 !== 0) {
              return <NewsArticle article={article} key={index} index={index} />
            }
          })}
        </div>
      </section>
    )
  }
}
