import { useIsInViewport, useIsMobile } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

const VideoAutoplay = url => {
  const player = useRef()
  const isMobile = useIsMobile()
  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    const restart = setInterval(() => {
      if (player.current) {
        player.current.seekTo(0)
      }
    }, 3000)

    return () => {
      clearInterval(restart)
    }
  }, [player])

  return (
    <div ref={setRef} className="project-listing__item-video objFit">
      <ReactPlayer
        url={url.url}
        autoPlay={true}
        light={false}
        controls={false}
        playing={isInViewport}
        muted={true}
        playsinline
        playsInline
        height={isMobile ? '100%' : '12em'}
        loop={true}
        width={isMobile ? '100%' : '20em'}
        ref={player}
      />
    </div>
  )
}

export default VideoAutoplay
