import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { Link } from 'gatsby'
import { CookiesPolicyPageLink } from './navigation/cookies-policy-page-link'

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const accepted = Cookies.get('gatsby-accepted-cookies') || false

  const [animate, setAnimate] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(!accepted)

  const close = () => {
    setAnimate(true)

    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 2000)
  }

  const accept = () => {
    Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-facebook-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-tiktok-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-hotjar', 'true', { expires: 30 })
    Cookies.set('gatsby-accepted-cookies', 'true', { expires: 30 })

    close()
  }

  const reject = () => {
    close()
  }

  return (
    <React.Fragment>
      {showCookieBanner && (
        <div
          className={`cookie-banner animate__animated ${
            animate ? 'animate__fadeOutDown' : ''
          } `}
        >
          <div className="cookie-banner__container">
            <div className="cookie-banner__message">
              This site uses <CookiesPolicyPageLink displayText="cookies" /> to
              give you the best possible experience as a user.
              <button onClick={accept} className="cookie-banner__agree">
                Accept
              </button>
            </div>
            <button onClick={reject} className="cookie-banner__reject">
              <div className="cookie-banner__reject-button">
                <span className="screenreader-text">Reject</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
