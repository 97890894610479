import React from 'react'

const LogoImage = ({ smallOnMobile }) => {
  const classes = `logo ${smallOnMobile ? 'small-on-mobile' : ''}`
  return (
    <>
      {smallOnMobile === 'true' && (
        <svg
          className="logo__small logo"
          width="100%"
          height="100%"
          viewBox="0 0 49 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.499 19.0295C6.42596 24.8573 4.63898 33.7131 7.48853 40H0C1.26166 38.0566 2.22113 34.1699 2.22113 28.1708V11.8292C2.22113 5.82776 1.2569 1.94336 0 0H11.2112C18.0713 0 22.1322 1.94336 22.1322 6.62939C22.1322 10.3746 19.0953 12.6608 16.067 14.9406C15.3525 15.4785 14.6384 16.0161 13.9648 16.5723L17.6371 25.999C19.5206 30.771 21.6494 35.4442 24.0151 40H17.8314C17.8314 37.5523 16.3323 31.1989 14.6892 27.0861L11.499 19.0295ZM4.43909 10.9058C3.80914 19.7181 3.17834 28.5414 2.21155 37.2574H2.22113C5.50726 25.8278 8.64948 21.2559 11.5878 17.9995C11.7284 17.8461 11.8749 17.6871 12.0263 17.5228C14.5779 14.7539 18.4982 10.5 18.4982 6.45813C18.4982 3.25641 16.5193 0.570862 10.283 0.570862C6.80249 0.570862 5.11151 1.88629 4.82123 5.59943C4.69208 7.36713 4.56561 9.13623 4.43909 10.9058ZM35.5379 28.1708C35.5379 34.1127 36.5046 37.9995 37.7614 40H30.2705C31.5346 38.0566 32.494 34.1699 32.494 28.1708V11.8292C32.494 5.82776 31.5274 1.94336 30.2705 0H48.5481L48.74 6.05853H48.644C46.6628 3.02808 42.6522 0.570862 39.6084 0.570862C37.1906 0.570862 35.3556 2.11462 35.3556 5.71362C35.3556 9.94293 37.9102 13.3135 43.6669 13.3135H46.5164V18.5728H46.4684C43.7148 16.0633 40.9108 13.951 38.8336 12.864C37.8088 12.3095 36.6929 11.9398 35.5379 11.7721V28.1708Z"
            fill="#E04C26"
          />
        </svg>
      )}
      <svg
        className={classes}
        width="100%"
        height="100%"
        viewBox="0 0 1337 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.2261 170.078C19.34 143.654 26.794 106.419 47.9471 81.9162L61.2434 115.788C68.093 133.084 74.3382 159.749 74.3382 170.078H100.125C91.6636 153.743 80.9863 130.682 73.5323 111.223L58.2215 71.5866C72.928 59.3352 92.268 49.2458 92.268 29.7877C92.268 10.0894 75.3455 1.92178 46.7384 1.92178H0C5.23792 10.0894 9.26709 26.4246 9.26709 51.648V120.352C9.26709 145.575 5.23792 161.911 0 170.078H31.2261ZM9.26709 158.547C14.1021 114.587 16.9225 69.905 20.1458 25.4637C21.3546 9.84915 28.4056 4.32401 42.9106 4.32401C68.8988 4.32401 77.1586 15.6145 77.1586 29.067C77.1586 47.0838 58.8259 66.0614 48.35 77.5922C36.0611 91.2849 22.9663 110.503 9.26709 158.547Z"
          fill="#E04C26"
        />
        <path
          d="M201.848 51.648C201.848 26.4246 205.878 10.0894 211.115 1.92178H179.889C185.127 10.0894 189.157 26.4246 189.157 51.648V70.6257H125.093V51.648C125.093 26.4246 129.122 10.0894 134.36 1.92178H103.134C108.372 10.0894 112.401 26.4246 112.401 51.648V120.352C112.401 145.575 108.372 161.911 103.134 170.078H134.36C129.122 161.911 125.093 145.575 125.093 120.352V73.0279H189.157V120.352C189.157 145.575 185.127 161.911 179.889 170.078H211.115C205.878 161.911 201.848 145.575 201.848 120.352V51.648Z"
          fill="#E04C26"
        />
        <path
          d="M213.72 1.92178C218.353 6.00558 221.778 13.2123 224.8 20.8994L263.883 122.034C263.682 146.296 259.652 162.151 254.616 170.078H286.245C281.007 161.911 276.978 145.575 276.978 120.352V116.268C278.59 111.704 287.252 89.1229 302.966 47.8045C317.27 10.0894 322.508 7.44692 326.537 1.92178H303.168C303.973 3.84357 304.779 6.96647 304.779 12.2514C304.779 19.9385 303.168 31.9497 297.728 51.1676C291.483 73.5084 277.985 106.659 275.568 112.425L241.32 23.7821C238.902 17.2961 238.096 12.0112 238.096 8.40781C238.096 4.08379 238.902 1.92178 238.902 1.92178H213.72Z"
          fill="#E04C26"
        />
        <path
          d="M323.825 162.151C334.3 167.196 347.798 172 367.742 172C397.76 172 415.891 154.944 415.891 130.201C415.891 99.933 392.925 86.9609 371.57 74.9497C352.633 64.3799 335.106 54.5307 335.106 34.352C335.106 15.3743 350.014 2.40223 364.922 2.40223C384.262 2.40223 403.199 25.2235 410.452 54.7709H410.855L406.422 9.12849C397.76 4.32401 388.09 0 368.145 0C344.373 0 324.429 15.8547 324.429 41.0782C324.429 67.743 344.978 79.7542 365.526 91.5251C385.269 102.816 405.012 113.866 405.012 137.648C405.012 159.749 388.291 169.117 372.779 169.117C345.381 169.117 329.465 150.38 320.803 102.816H320.4L323.825 162.151Z"
          fill="#E04C26"
        />
        <path
          d="M496.53 170.078C491.292 161.67 487.263 145.335 487.263 120.352V51.4078C492.098 52.1285 495.725 53.3296 500.962 55.9721C509.625 60.5363 521.31 69.4246 532.793 79.9944H532.994V57.8939H521.108C497.135 57.8939 486.457 43.7207 486.457 25.9441C486.457 10.81 494.113 4.32401 504.186 4.32401C516.878 4.32401 533.599 14.6536 541.859 27.3855H542.261L541.456 1.92178H465.304C470.542 10.0894 474.571 26.4246 474.571 51.648V120.352C474.571 145.575 470.542 161.911 465.304 170.078H496.53Z"
          fill="#E04C26"
        />
        <path
          d="M576.843 170.078C564.957 143.654 572.411 106.419 593.564 81.9162L606.86 115.788C613.71 133.084 619.955 159.749 619.955 170.078H645.741C637.28 153.743 626.603 130.682 619.149 111.223L603.838 71.5866C618.545 59.3352 637.885 49.2458 637.885 29.7877C637.885 10.0894 620.962 1.92178 592.355 1.92178H545.617C550.855 10.0894 554.884 26.4246 554.884 51.648V120.352C554.884 145.575 550.855 161.911 545.617 170.078H576.843ZM554.884 158.547C559.719 114.587 562.539 69.905 565.762 25.4637C566.971 9.84915 574.022 4.32401 588.527 4.32401C614.515 4.32401 622.775 15.6145 622.775 29.067C622.775 47.0838 604.442 66.0614 593.967 77.5922C581.678 91.2849 568.583 110.503 554.884 158.547Z"
          fill="#E04C26"
        />
        <path
          d="M757.941 170.078C751.293 158.067 742.63 138.849 730.744 101.374L698.914 1.92178H692.668V26.4246C689.848 33.6313 679.372 56.2123 660.838 129.24C657.212 143.413 652.78 158.547 647.743 170.078H669.904C669.501 163.832 669.299 157.827 669.299 151.581C669.299 128.279 672.321 106.179 676.35 87.2011H711.605L721.275 117.709C730.945 148.698 730.744 156.145 730.744 170.078H757.941ZM676.753 84.7989C682.998 56.4525 691.057 36.0335 693.676 29.7877L711.001 84.7989H676.753Z"
          fill="#E04C26"
        />
        <path
          d="M784.111 170.078C777.866 150.86 775.247 125.156 777.463 86.9609L781.089 26.4246L828.029 170.078H834.677V152.061L855.226 86C862.277 63.1788 871.746 41.0782 876.379 30.7486L880.811 120.352C881.617 136.927 880.61 158.067 873.962 170.078H903.576C899.144 159.989 895.316 144.134 894.108 120.352L889.071 18.7374L897.129 1.92178H878.394L834.677 144.134L787.939 1.92178H773.031L779.075 20.6592L775.247 86.9609C773.031 125.156 767.592 150.86 759.936 170.078H784.111Z"
          fill="#E04C26"
        />
        <path
          d="M937.789 170.078C925.299 142.453 933.558 103.777 956.928 79.2737C971.433 62.6983 998.831 53.0894 998.831 29.7877C998.831 10.0894 981.908 1.92178 953.301 1.92178H906.563C911.801 10.0894 915.83 26.4246 915.83 51.648V120.352C915.83 145.575 911.801 161.911 906.563 170.078H937.789ZM915.83 158.547C920.665 114.587 923.486 69.905 926.709 25.4637C927.918 9.84915 934.969 4.32401 949.474 4.32401C975.462 4.32401 983.722 15.6145 983.722 29.067C983.722 47.0838 965.389 66.0614 954.913 77.5922C942.624 91.2849 929.529 110.503 915.83 158.547Z"
          fill="#E04C26"
        />
        <path
          d="M1001.6 28.3464C1009.66 15.6145 1017.92 4.32401 1029.4 4.32401C1039.48 4.32401 1043.71 10.0894 1043.71 25.2235V120.352C1043.71 145.575 1039.68 161.911 1034.44 170.078H1065.67C1060.43 161.67 1056.4 145.335 1056.4 120.352L1056.2 25.2235C1056.2 10.0894 1060.43 4.32401 1070.5 4.32401C1081.98 4.32401 1090.45 15.6145 1098.5 28.3464H1098.91L1096.49 1.92178H1003.62L1001.2 28.3464H1001.6Z"
          fill="#E04C26"
        />
        <path
          d="M1230.78 86.4804C1230.78 37.2346 1199.76 0 1160.47 0C1122.6 0 1091.98 37.2346 1091.98 85.0391C1091.98 134.525 1123 172 1162.29 172C1200.16 172 1230.78 134.525 1230.78 86.4804ZM1218.69 99.4525C1218.69 135.966 1200.97 166.475 1172.16 166.475C1135.09 166.475 1104.06 120.352 1104.06 72.3073C1104.06 35.5531 1121.79 5.2849 1150.6 5.2849C1187.47 5.2849 1218.69 50.9274 1218.69 99.4525Z"
          fill="#E04C26"
        />
        <path
          d="M1257.22 170.078C1253.39 165.274 1245.94 147.737 1245.94 102.335V25.4637L1320.08 170.078H1327.73V69.6648C1327.73 24.2626 1333.78 6.72625 1337 1.92178H1314.24C1318.06 6.72625 1325.52 24.2626 1325.52 69.6648V147.017L1249.97 1.92178H1233.85L1243.72 21.1397V102.335C1243.72 147.737 1237.68 165.274 1234.46 170.078H1257.22Z"
          fill="#E04C26"
        />
      </svg>
    </>
  )
}

export default LogoImage
