import React, { useEffect, useState } from 'react'

export const Time = () => {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 30000)
    return () => {
      clearInterval(timer)
    }
  }, [setTime])

  return (
    <span>
      {time.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/London',
      })}
    </span>
  )
}
