import React, { useCallback, useContext } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import { Link } from 'gatsby'
import { animate } from '../../helpers/animator'
import { linkResolver } from '../../utils/linkResolver'
import { TextColourContext } from '../../context/text-colour-context-provider'
import { ImageLayout } from '@superrb/gatsby-addons/types'

export const RelatedProjects = ({ data }) => {
  const { textColour } = useContext(TextColourContext)

  /**
   * @type {RelatedProjects}
   */
  const toggleProjects = useCallback(e => {
    let project = document.querySelector(
      '[data-project-index="' +
        e.target.parentElement.dataset.titleIndex +
        '"]',
    )
    let targetParent = e.target.parentElement
    let defaultProject = document.querySelector('[data-project-index="0"]')
    let defaultTitle = document.querySelector('[data-title-index="0"]')

    if (project) {
      if (project.classList.contains('is-active')) {
        project.classList.remove('is-active')
        targetParent.classList.remove('is-active')
        defaultProject.classList.add('default-active')
        defaultTitle.classList.add('default-active')
      } else {
        project.classList.add('is-active')
        targetParent.classList.add('is-active')
        defaultProject.classList.remove('default-active')
        defaultTitle.classList.remove('default-active')
      }
    }
  }, [])

  if (!data || Object.keys(data).length < 1) {
    return null
  }

  let nodata = true
  Object.values(data).forEach(item => {
    if (item?.project?.document !== null) {
      nodata = false
    }
  })

  if (nodata === true) {
    return null
  }

  return (
    <div
      className={`related-projects ${
        textColour === 'dark'
          ? 'related-projects--white'
          : 'related-projects--orange'
      }`}
      ref={animate}
    >
      <h2 className="related-projects__title">Related Projects</h2>
      <div className="related-projects__images">
        {data.map((project, index) => {
          const projectData = project?.project?.document?.data

          if (!projectData) {
            return
          }

          const image = projectData.cover_image?.fluid
            ? projectData.cover_image
            : projectData.main_image
          const title =
            projectData.meta_navigation_title || projectData.page_title
          const client = projectData.client

          const url = linkResolver(project.project.document)
          return (
            <div
              key={index}
              className={`related-project${
                index == 0 ? ' default-active' : ''
              }`}
              data-project-index={index}
            >
              <Image
                image={image}
                alt={image.alt ? image.alt : title}
                className="related-project__image"
                layout={ImageLayout.cover}
              />
              <div className="related-project__title">
                <Link to={url} className="related-project__title-link">
                  {title}
                </Link>
              </div>
              <div className="related-project__client">{client}</div>
            </div>
          )
        })}
      </div>
      <div className="related-projects__titles">
        <div className="related-projects__titles-heading">Related Projects</div>
        {data.map((project, index) => {
          const projectData = project?.project?.document?.data

          if (!projectData) {
            return
          }

          const title =
            projectData.meta_navigation_title || projectData.page_title
          const url = linkResolver(project.project.document)

          //Desktop
          return (
            <div
              key={index}
              className={`related-projects__titles-item ${
                index == 0 ? ' default-active' : ''
              }`}
              data-title-index={index}
            >
              <Link
                onMouseOver={e => toggleProjects(e)}
                onMouseOut={e => toggleProjects(e)}
                to={url}
              >
                {title}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RelatedProjects
