import React, { useState, useEffect, useContext } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { NewsArticles } from '../components/news-articles'
import NewsFeedPage from '../types/pages/news-feed'
import { TextColourContext } from '../context/text-colour-context-provider'

export const query = graphql`
  query NewsFeedQuery {
    prismicNewsfeed {
      ...NewsFeedPage
    }
  }
`

const NewsFeed = ({ data }) => {
  const { setTextColour } = useContext(TextColourContext)
  useEffect(() => {
    setTextColour('light')
  }, [setTextColour])

  let [articles, setArticles] = useState([])
  if (!data) return null

  const pageData = data.prismicNewsfeed.data

  if (articles.length === 0) {
    pageData.articles.forEach(article => articles.push(article))
    articles.reverse()
  }

  return (
    <>
      <Seo data={pageData} />
      {pageData?.page_title && (
        <h1 className="page-title">{pageData.page_title}</h1>
      )}
      <NewsArticles articles={articles} />
    </>
  )
}
export default withPrismicPreview(NewsFeed)
