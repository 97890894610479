import { useEventListener } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FilterContext } from '../../context/filter-context-provider'
import scrollTo from '../../helpers/scroll-to'
import animate from '../../utils/animate'

export const Filter = () => {
  const {
    selectedTags = [],
    availableTags = [],
    expanded = false,
    addTag,
    removeTag,
    setExpanded,
    toggleExpanded,
    listingOpacity,
    setListingOpacity,
  } = useContext(FilterContext)

  const [isMobile, setIsMobile] = useState(true)

  const expandFilters = useCallback(
    event => {
      event.preventDefault()
      toggleExpanded()
    },
    [toggleExpanded],
  )

  const handleResize = useCallback(() => {
    setIsMobile(!window.matchMedia('(min-width: 63.75em)').matches)
  }, [setExpanded])

  useEventListener(
    'resize',
    handleResize,
    {},
    typeof window !== 'undefined' ? window : undefined,
  )

  useEffect(() => {
    handleResize()
  }, [handleResize])

  return (
    <aside className="project-filter">
      <ul className="project-filter__items">
        <li className="project-filter__item">
          <button
            className="project-filter__button project-filter__toggle"
            onClick={expandFilters}
            aria-expanded={expanded}
            aria-hidden={!isMobile}
            key="toggle"
          >
            <span
              className="project-filter__button-label"
              data-title={
                expanded
                  ? 'Close filters'
                  : selectedTags.length > 0
                  ? 'Clear filters'
                  : 'Filter work'
              }
            >
              {expanded
                ? 'Close filters'
                : selectedTags.length > 0
                ? 'Clear filters'
                : 'Filter work'}
            </span>
          </button>
        </li>
        {availableTags.map(tag => {
          const selected = selectedTags.includes(tag)

          const handleClick = async () => {
            const fn = selected ? removeTag : addTag
            await animate(1, 0, setListingOpacity)
            await fn(tag)
            await scrollTo(0, 0)
            await animate(0, 1, setListingOpacity)
          }

          return (
            <li
              className="project-filter__item"
              key={tag}
              aria-hidden={isMobile && !expanded}
            >
              <button
                className="project-filter__button"
                onClick={() => handleClick(tag)}
                aria-checked={selected}
                key={tag}
              >
                <span className="project-filter__button-label" data-title={tag}>
                  {tag}
                </span>
              </button>
            </li>
          )
        })}
      </ul>
    </aside>
  )
}
