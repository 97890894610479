import React from 'react'
import LogoImage from '../images/logo.svg.js'

const Logo = ({ smallOnMobile }) => {
  return (
    <div className="logo">
      <LogoImage smallOnMobile={smallOnMobile} />
    </div>
  )
}

export default Logo
