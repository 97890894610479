import React, { Component } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'

export const CookiesPolicyPageLink = ({ displayText }) => {
  const queryData = useStaticQuery(graphql`query CookiesPolicyPageQuery {
  allPrismicLegalpage(
    sort: {last_publication_date: ASC}
    limit: 1
    filter: {data: {is_cookie_policy: {eq: true}}}
  ) {
    nodes {
      data {
        meta_navigation_title
      }
      uid
      type
    }
  }
}`)

  return (
    <>
      {queryData.allPrismicLegalpage.nodes.map((legalPage, index) => {
        const url = linkResolver(legalPage)

        return (
          <Link to={url} key={index}>
            {displayText}
          </Link>
        )
      })}
    </>
  )
}
