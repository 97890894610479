import React, { useContext, useEffect } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { RichText } from '@superrb/gatsby-addons/components'
import { Seo } from '../components/seo'
import { CustomLink } from '../utils/custom-link'
import { Image } from '@superrb/gatsby-addons/components'
import BiographyPage from '../types/pages/biography'
import { TextColourContext } from '../context/text-colour-context-provider'

export const query = graphql`
  query BiographyQuery {
    prismicBiography {
      ...BiographyPage
    }
  }
`

const Biography = ({ data }) => {
  if (!data) return null

  const { setTextColour } = useContext(TextColourContext)
  useEffect(() => {
    setTextColour('dark')
  }, [setTextColour])

  const pageData = data.prismicBiography.data
  const pageTitle = pageData?.page_title
  const clientsTitle = pageData?.clients_title?.richText
  const clientsText = pageData?.biography_clients?.richText
  const biographyText = pageData?.biography_text.richText
  const biographyImage = pageData.biography_image
  const biographyImageAlt = pageData.biography_image?.alt

  return (
    <>
      <Seo data={pageData} />
      <div className="biography__content">
        {biographyImage && (
          <Image
            image={biographyImage}
            alt={biographyImageAlt}
            sizes="(min-width: 63.75em) 50vw, 100vw"
          />
        )}
        {pageTitle && <h1>{pageTitle}</h1>}
        {biographyText && (
          <div className="biography__text">
            <RichText field={biographyText} />
          </div>
        )}
        {clientsTitle && (
          <div className="clients-title">
            <RichText field={clientsTitle} />
          </div>
        )}
        {clientsText && (
          <div className="biography__text-clients">
            <RichText field={clientsText} />
          </div>
        )}
      </div>
    </>
  )
}
export default withPrismicPreview(Biography)
