import React, { Component } from 'react'
import ContactFormResource from './contact-form-resource'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ContactInput } from './contact-input'
import { ContactTextarea } from './contact-textarea'
import scrollToElement from '../../helpers/scroll-to-element'

// NOTE if the route to this component changes from `/contact` it will need
// to be updated in the `onRouteUpdate` in `gatsby-browser.js
// or the Recaptcha won't show

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      company: '',
      subject: '',
      message: '',
      recaptchaToken: '',
      invalidFields: [],
      formValid: false,
      formSubmitted: false,
      formSuccess: false,
      formFailed: true,
      recaptchaFail: false,
      posting: false,
    }
  }

  checkFailure(response) {
    response.json().then(body => {
      if (body.message === 'RECAPTCHA_MISSING') {
      }

      if (body.message === 'RECAPTCHA_LOW') {
      }

      this.setState({
        formFailed: true,
        posting: false,
        formSubmitted: false,
        recaptchaFail:
          body.message === 'RECAPTCHA_MISSING' ||
          body.message === 'RECAPTCHA_LOW',
      })
    })
  }

  scrollToSuccess() {
    scrollToElement(this.thankYouRef)
  }

  scrollToFail() {
    scrollToElement(this.contactFormRef)
  }

  sendMail = () => {
    // get the user data out of the state
    const { name, email, message, company, recaptchaToken } = this.state

    // This matches the interface the lambda function expects
    const data = { name, email, message, company, recaptchaToken }

    const resource = new ContactFormResource(data)

    const complete = async response => {
      const { status } = response

      if (status === 200) {
        this.setState(
          {
            formSuccess: true,
          },
          () => {
            this.scrollToSuccess()
          },
        )
      } else {
        this.checkFailure(response)
      }
    }

    resource.post().then(complete)
  }

  submit = () => {
    const required = ['name', 'email', 'company', 'message']

    const validatedFields = required
      .map(field => this.state[field] !== '')
      .filter(value => value === true)

    const invalidFields = required
      .map(field => (this.state[field] === '' ? field : null))
      .filter(value => value !== null)

    const validatedForm = validatedFields.length === required.length

    this.setState(
      {
        formSubmitted: true,
        posting: validatedForm,
        formValid: validatedForm,
        invalidFields: invalidFields,
      },
      () => {
        if (validatedForm === true) {
          this.sendMail()
        } else {
          this.scrollToFail()
        }
      },
    )
  }

  isInvalidField(name) {
    return this.state.invalidFields.includes(name)
  }

  setToken(recaptchaToken) {
    this.setState({
      recaptchaToken: recaptchaToken,
    })
  }

  setThankYouRef = ref => (this.thankYouRef = ref)

  setContactFormRef = ref => (this.contactFormRef = ref)

  onVerify = token => {
    this.setToken(token)
  }

  renderForm() {
    return (
      <>
        <div className="contact-form" ref={this.setContactFormRef}>
          <div>
            <form className="contact-form__form">
              <ContactInput
                failed={this.isInvalidField('name')}
                id="contact-name"
                label="Name*"
                onChange={data => this.setState({ name: data })}
                value={this.state.name}
              />

              <ContactInput
                failed={this.isInvalidField('email')}
                id="contact-email"
                label="Email*"
                onChange={data => this.setState({ email: data })}
                value={this.state.email}
              />

              <ContactInput
                failed={this.isInvalidField('company')}
                id="contact-company"
                label="Company*"
                onChange={data => this.setState({ company: data })}
                value={this.state.company}
              />

              <ContactTextarea
                failed={this.isInvalidField('message')}
                id="contact-message"
                label="Enquiry details*"
                onChange={data => this.setState({ message: data })}
                value={this.state.message}
              />

              {this.state.recaptchaFail ? (
                <span className="contact-form__required">
                  Please disable any advert/content blockers.
                </span>
              ) : null}

              <button
                className="contact-form__submit"
                type="button"
                onClick={this.submit}
                data-title={
                  this.state.formValid && this.state.posting
                    ? 'Sending Message'
                    : 'Send Message'
                }
                disabled={this.state.posting}
              >
                {this.state.formValid && this.state.posting
                  ? 'Sending Message'
                  : 'Send Message'}
              </button>

              <GoogleReCaptcha onVerify={this.onVerify} />

            </form>

            <p className="recaptcha-text">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Terms of Service</a> apply.</p>
          </div>
        </div>
      </>
    )
  }

  renderThankYou() {
    return (
      <div className="contact-form" ref={this.setThankYouRef}>
        <div className="contact-form__thank-you">
          <div className="contact-form__thank-you__message">
            Your message has been successfully sent!
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.formSuccess === true) {
      return this.renderThankYou()
    }

    return this.renderForm()
  }
}

export default ContactForm
