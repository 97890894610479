import { Link } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { FilterContext } from '../../context/filter-context-provider'
import useParallax from '../../hooks/use-parallax'
import { linkResolver } from '../../utils/linkResolver'
import { Image } from '@superrb/gatsby-addons/components'
import VideoAutoplay from './video-autoplay'

export const Listing = ({ projects = [] }) => {
  const {
    selectedTags = [],
    addTag,
    setAvailableTags,
    listingOpacity,
  } = useContext(FilterContext)
  const [filteredProjects, setFilteredProjects] = useState([])

  useParallax('.project-listing__item')

  useEffect( () => {
    setFilteredProjects(
      projects.filter(project => {
        if (selectedTags.length === 0) {
          if (!project.data.hide_project) {
            return true
          } else {
            return false
          }
        }

        return (
          project.tags.filter(tag => selectedTags.includes(tag)).length ===
          selectedTags.length
        )
      }),
    )
  }, [selectedTags, setFilteredProjects, setAvailableTags])

  useEffect( () => {
    setAvailableTags(
      filteredProjects.reduce((availableTags, project) => {
        project.tags.forEach(tag => availableTags.push(tag))

        return availableTags
      }, []),
    )
  }, [filteredProjects])

  return (
    <ul
      className="project-listing"
      style={{ opacity: listingOpacity }}
      data-header-modifier="light"
    >
      {filteredProjects.map((project, i) => {
        return (
          <li
            className="project-listing__item"
            key={project.uid}
            data-index={i}
          >
            <Link className="project-listing__link" to={linkResolver(project)}>
              {project.data.cover_image && !project?.data?.autoplay_video && (
                <Image
                  image={project.data.cover_image}
                  alt={
                    project.data.cover_image.alt
                      ? project.data.cover_image.alt
                      : project.data.page_title
                  }
                  className="project-listing__item-image"
                  sizes="(min-width: 63.75em) 33vw, 50vw"
                />
              )}
              {project?.data?.autoplay_video && (
                <VideoAutoplay url={project.data.autoplay_video} />
              )}
              <div className="project-listing__item-content">
                <h3 className="project-listing__item-title">
                  {project.data.meta_navigation_title
                    ? project.data.meta_navigation_title
                    : project.data.page_title}
                </h3>
                <span className="project-listing__item-client">
                  {project.data.client}
                </span>
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
