import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import ReactPlayer from 'react-player'
import useLockBodyScroll from '../hooks/use-lock-body-scroll'
import Logo from './logo'
import { useEventListener } from '@superrb/gatsby-addons/hooks'

const VideoOverlay = ({ url }) => {
  const [isPortrait, setIsPortrait] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [isOverlayVisible, setIsOverlayVisible] = useState(true)
  const [hasSound, setHasSound] = useState(false)
  const [volume, setVolume] = useState(false)
  const [screenSize, setScreenSize] = useState({ width: 1024, height: 768 })
  useLockBodyScroll(
    isOverlayVisible && (typeof window === 'undefined' || !window.previousPath),
  )

  const {
    header: {
      data: { strapline },
    },
  } = useStaticQuery(graphql`
    query VideoOverlayQuery {
      header: prismicHeader {
        ...Header
      }
    }
  `)

  const [buttonClass, setButtonClass] = useState(
    Cookies.get('gatsby-accepted-cookies') || false,
  )

  useEventListener(
    'click',
    e => {
      if (
        e.target.classList.contains('cookie-banner__agree') ||
        e.target.classList.contains('cookie-banner__reject-button')
      ) {
        setButtonClass(true)
      }
    },
    {},
    typeof window !== 'undefined' ? window : undefined,
  )

  useEffect(() => {
    setTimeout(() => {
      const isVideoClosed = sessionStorage.getItem('video-overlay-closed')
      if (isVideoClosed === 'true') {
        setIsOverlayVisible(false)
        return
      }

      setIsVideoVisible(true)
    }, 2000)
  }, [setIsOverlayVisible, setIsVideoVisible])

  const handleResize = useCallback(() => {
    setIsPortrait(window.matchMedia('(orientation: portrait)').matches)
  }, [setIsPortrait])

  useEffect(() => {
    setIsPortrait(window.matchMedia('(orientation: portrait)').matches)
  }, [])

  useEventListener(
    'resize',
    handleResize,
    {},
    typeof window !== 'undefined' ? window : undefined,
  )

  useEffect(() => {
    setScreenSize({ height: window.innerHeight, width: window.innerWidth })
  }, [setScreenSize])

  const toggleSound = useCallback(() => {
    setHasSound(hasSound => !hasSound)
  }, [setHasSound])

  const closeVideo = useCallback(() => {
    setIsOverlayVisible(false)
    sessionStorage.setItem('video-overlay-closed', 'true')
  }, [setIsOverlayVisible])

  const handlePlay = useCallback(() => {
    setIsPlaying(true)
  }, [setIsPlaying])

  return (
    <div
      className="video-overlay"
      aria-hidden={
        !isOverlayVisible ||
        (typeof window !== 'undefined' && !!window.previousPath)
      }
    >
      <div className="video-overlay__wrapper">
        <figure className="video-overlay__video" aria-hidden={!isVideoVisible}>
          <ReactPlayer
            url={url}
            playing={isOverlayVisible}
            volume={!hasSound ? 0 : 1}
            autoPlay={true}
            className="video-overlay__player"
            width={`${isPortrait ? screenSize.height * 2 : screenSize.width}px`}
            height={`${
              isPortrait ? screenSize.height : screenSize.width * 2
            }px`}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                  width: isPortrait
                    ? `${screenSize.height * 2}px`
                    : `${screenSize.width}px`,
                  height: isPortrait
                    ? `${screenSize.height}px`
                    : `${screenSize.width * 2}px`,
                },
              },
            }}
            playsinline={true}
            onPlay={handlePlay}
            onEnded={closeVideo}
            onError={closeVideo}
          />
        </figure>

        <div className="video-overlay__content">
          <h1 className="video-overlay__strapline">{strapline}</h1>
          <Logo />
        </div>

        <button
          className={`video-overlay__skip primary button-swoop ${
            !buttonClass ? 'accepted-cookies' : 'non-accepted-cookies'
          }`}
          onClick={closeVideo}
          aria-hidden={!isVideoVisible}
          data-title="Skip Intro"
        >
          Skip Intro
        </button>

        <button
          className={`video-overlay__skip secondary button-swoop ${
            buttonClass ? 'accepted-cookies' : 'non-accepted-cookies'
          }`}
          onClick={closeVideo}
          aria-hidden={!isVideoVisible}
          data-title="Skip Intro"
        >
          Skip Intro
        </button>

        <button
          className="video-overlay__mute"
          onClick={toggleSound}
          aria-hidden={!isVideoVisible}
          aria-checked={hasSound}
        >
          <span className="screenreader-text">
            {hasSound ? 'Mute' : 'Unmute'} video
          </span>
        </button>
      </div>
    </div>
  )
}

export default VideoOverlay
