import * as React from 'react'
import { RichText } from '@superrb/gatsby-addons/components'
import { CustomLink } from '../utils/custom-link'
import { Image } from '@superrb/gatsby-addons/components'
import { linkResolver } from '../utils/linkResolver'
import { animate } from '../helpers/animator'

export const NewsArticle = ({ article, index }) => {
  return (
    <article
      key={index}
      className="news-articles__article"
      data-index={index}
      ref={animate}
    >
      <div class="news-articles__article__inner">
        {article?.image && article?.link?.url && (
          <a
            href={article?.link?.url}
            target={article?.link?.target ? article?.link?.target : ''}
            className="news-articles__article-link"
          >
            <Image
              image={article.image}
              alt={article.image.alt}
              className="news-articles__article-image"
              sizes="(min-width: 63.75em) 50vw, 100vw"
            />
          </a>
        )}
        {article.image && !article?.link?.url && (
          <Image
            image={article.image}
            alt={article.image.alt}
            className="news-articles__article-image"
            sizes="(min-width: 63.75em) 50vw, 100vw"
          />
        )}
        <div className="news-articles__article-info">
          {article.category && <span>{article.category}</span>}
          <span> - </span>
          {article.date && <span>{article.date}</span>}
        </div>
        <div className="news-articles__article-content">
          {article.description?.richText && (
            <RichText field={article.description.richText} />
          )}
        </div>
      </div>
    </article>
  )
}
