import { RichText } from '@superrb/gatsby-addons/components'
import React, { useCallback, useContext, useEffect } from 'react'
import { TextColourContext } from '../../context/text-colour-context-provider'
import { animate } from '../../helpers/animator'
import { CustomLink } from '../../utils/custom-link'
import { Image } from '@superrb/gatsby-addons/components'
import ProjectVideo from './video'

export const ProjectContent = ({
  title,
  description,
  content,
  backgroundColour,
}) => {
  /**
   * @type {ProjectContent}
   */
  const { textColour } = useContext(TextColourContext)
  const setBackgroundColour = useCallback(() => {
    document.body.style.backgroundColor = backgroundColour
  }, [])

  useEffect(() => {
    setBackgroundColour()
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [setBackgroundColour])

  const classes = [
    'project-content',
    textColour === 'light'
      ? 'project-content--orange'
      : 'project-content--white',
  ].join(' ')

  return (
    <div className={classes}>
      <div className="project-content__media">
        {content.map((item, index) => {
          switch (item.size) {
            case 'small':
              item.image.sizes = '(min-width: 63.75em) 30vw, 50vw'
              break
            case 'medium':
              item.image.sizes = '(min-width: 63.75em) 50vw, 100vw'
            default:
              item.image.sizes = '100vw'
              break
          }

          return (
            <div
              className={`project-content__media-item item item--${
                item.position
              } item--${item.size} ${item.margin ? 'item--no-margin' : ''}`}
              key={index}
            >
              <div
                className="project-content__media-item-inner"
                data-index={index}
              >
                {item.media?.url && (
                  <ProjectVideo video={item.media} image={item.image} />
                )}

                {item.image && item.media?.url === null && (
                  <Image
                    image={item.image}
                    alt={item.image.alt}
                    sizes={item.image.sizes}
                    className="project-content__media-item-image"
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="project-content__description" ref={animate}>
        <h2 className="project-content__description-title">{title}</h2>
        <div className="project-content__description-text">
          {description?.richText && <RichText field={description.richText} />}
        </div>
      </div>
    </div>
  )
}

export default ProjectContent
