import React, { useContext, useEffect } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { RichText } from '@superrb/gatsby-addons/components'
import { Seo } from '../components/seo'
import { CustomLink } from '../utils/custom-link'
import ContactForm from '../components/contact/contact-form'
import ContactPage from '../types/pages/contact'
import { TextColourContext } from '../context/text-colour-context-provider'

export const query = graphql`
  query ContactQuery {
    prismicContact {
      ...ContactPage
    }
  }
`

const Contact = ({ data }) => {
  const { setTextColour } = useContext(TextColourContext)
  useEffect(() => {
    setTextColour('light')
  }, [setTextColour])

  if (!data) return null

  const pageData = data.prismicContact.data

  return (
    <>
      <Seo data={pageData} />
      <div className="contact__content">
        {pageData.page_title && <h1>{pageData.page_title}</h1>}
        <div className="contact__message">
          {pageData.page_text.richText && (
            <RichText field={pageData.page_text.richText} />
          )}
        </div>
        <ContactForm />
      </div>
    </>
  )
}
export default withPrismicPreview(Contact)
