exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-feed-js": () => import("./../../../src/pages/news-feed.js" /* webpackChunkName: "component---src-pages-news-feed-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

