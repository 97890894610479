import { useEventListener, useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { NavContext } from '../context/nav-context-provider'
import { TextColourContext } from '../context/text-colour-context-provider'
import Logo from './logo'
import MenuToggle from './navigation/menu-toggle'
import Navigation from './navigation/navigation'

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicHeader {
        ...Header
      }
    }
  `)

  const top_right_link = data.prismicHeader.data.instagram_link.url
  const top_right_label = data.prismicHeader.data.instagram_name
  const strapline = data.prismicHeader.data.strapline
  const email = data.prismicHeader.data.email
  let lastScroll = 0

  const header = useRef(null)
  const { navOpen, toggleNav } = useContext(NavContext)
  const { textColour } = useContext(TextColourContext)

  const handleScroll = useCallback(async () => {
    if (!header.current) {
      return
    }

    const currentScroll = window.scrollY
    const headerHeight = header.current.clientHeight

    requestAnimationFrame(() => {
      // if (currentScroll > 50 && currentScroll > headerHeight) {
      //   document.body.classList.add('is-scrolling')
      // } else if (currentScroll < 50 || currentScroll < headerHeight + 100) {
      //   document.body.classList.remove('is-scrolling')
      // }

      if (
        document.body.classList.contains('page--home') &&
        window.scrollY > headerHeight + 25
      ) {
        document.body.classList.add('has-scrolled')
        document.body.classList.add('first-scroll')
      } else {
        document.body.classList.remove('has-scrolled')
      }

      lastScroll = currentScroll
    })
  })

  useEventListener(
    'scroll',
    handleScroll,
    { passive: true },
    typeof window !== 'undefined' ? window : undefined,
  )

  return (
    <>
      <header className={`header header--modifier-${textColour}`} ref={header}>
        <MenuToggle
          target="main-nav"
          className="header__menu-toggle"
          activeClassName="header__menu-toggle--active"
          label="Menu"
          target="main-nav"
        />
        {strapline && <p className="header__strapline">{strapline}</p>}
        {top_right_link && (
          <a
            href={top_right_link}
            target="_blank"
            className="header__instagram"
          >
            <span>{top_right_label}</span>
          </a>
        )}
        <Link to="/" className="header__logo">
          <Logo smallOnMobile="true" />
          <span className="screenreader-text">{siteTitle}</span>
        </Link>
      </header>
      <nav className="header header__overlay" data-nav-hidden={!navOpen}>
        <MenuToggle
          target="main-nav"
          className="header__menu-toggle"
          label="Close"
        />
        {top_right_link && (
          <a
            href={top_right_link}
            target="_blank"
            className="header__instagram"
          >
            <span>{top_right_label}</span>
          </a>
        )}
        <Link
          to="/"
          className="header__logo"
          onClick={toggleNav.bind({}, 'main-nav')}
        >
          <Logo />
          <span className="screenreader-text">{siteTitle}</span>
        </Link>
        <Navigation />
        {email && (
          <a
            className="button-swoop header__mail"
            data-title={email}
            href={`mailto:${email}`}
          >
            {email}
          </a>
        )}
      </nav>
    </>
  )
}

export default Header
