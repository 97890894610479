import { LiveNodeList } from 'live-node-list'
import { useCallback, useEffect, useRef } from 'react'
import isInViewport from '../utils/is-in-viewport'

const useParallax = (selector: string, level: number = 1): void => {
  const elements = useRef(null)

  const handleScroll = useCallback(
    event => {
      if (!elements.current) {
        return
      }

      elements.current.forEach(element => {
        requestAnimationFrame(() => {

        if (!isInViewport(element)) {
          return
        }
      })

        const top = element.getBoundingClientRect().top
        const i = parseInt(element.dataset.index, 10)
        const pos = `${(top / ((18 + i) % 3) / -25) * level}%`
        const scale =
          top <= window.innerHeight * 0.7
            ? 1
            : Math.min((window.innerHeight * 0.7) / top, 1)
        requestAnimationFrame(() => {
          element.style.transform = `translateY(${pos})`
          // element.style.transform = `scale(${scale})`
          element.style.opacity = scale
        })
      })
    },
    [elements],
  )

  useEffect(() => {
    elements.current = new LiveNodeList(selector)
    elements.current.addDelegatedEventListener(window, 'scroll', handleScroll, {
      passive: false,
    })

    return () => {
      if (elements.current) {
        elements.current.removeDelegatedEventListener(
          window,
          'scroll',
          handleScroll,
        )
      }
    }
  }, [])
}

export default useParallax
