import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import './src/stylesheets/main.sass'
import { FilterContextProvider } from './src/context/filter-context-provider'
import { Layout } from './src/components/layout'
import { NavContextProvider } from './src/context/nav-context-provider'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { recaptchaShowHide } from './src/helpers/recaptcha-show-hide'
import { LocationProvider } from '@reach/router'
import TextColourContextProvider from './src/context/text-colour-context-provider'
import { LazyLoadingContextProvider, PreloadContextProvider, TranslationContextProvider } from '@superrb/gatsby-addons/context'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <PreloadContextProvider>
      <LazyLoadingContextProvider>
        <TranslationContextProvider>
          <LocationProvider>
            <FilterContextProvider>
              <NavContextProvider>
                <TextColourContextProvider>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
                  >
                    <Layout prevLocation>{element}</Layout>
                  </GoogleReCaptchaProvider>
                </TextColourContextProvider>
              </NavContextProvider>
            </FilterContextProvider>
          </LocationProvider>
        </TranslationContextProvider>
      </LazyLoadingContextProvider>
    </PreloadContextProvider>
  </PrismicPreviewProvider>
)


export const onRouteUpdate = ({ location: { pathname }, prevLocation }) => {
  if ('navOpenProvider' in window) {
    Object.keys(navOpen).forEach(key =>
      closeNav.call(window.navOpenProvider, key),
    )
  }

  if (pathname === '/') {
    pathname = 'home'
  }

  window.scrollTo(0, -10)

  document.body.setAttribute(
    'class',
    `page ${[
      ...pathname
        .split('/')
        .filter(x => !!x)
        .map(slug => `page--${slug}`),
    ].join(' ')}`,
  )

  window.locations = window.locations || [document.referrer]
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href)
  }
  window.previousPath = window.locations[locations.length - 2]
}
