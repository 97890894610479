class ContactFormResource {
  constructor(data) {
    this.data = data
  }

  payload() {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.data),
    }
  }

  post() {
    return new Promise((resolve, reject) => {
      const url = '/.netlify/functions/contact'

      const payload = this.payload()

      fetch(url, payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default ContactFormResource
