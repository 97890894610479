import React, { Component } from 'react'
import { NavContext } from '../../context/nav-context-provider'

class MenuToggle extends Component {
  render() {
    const {
      target,
      label,
      className,
      activeClassName,
      toggleNav,
      navOpen,
      ...props
    } = this.props

    return (
      <NavContext.Consumer>
        {({ navOpen, toggleNav }) => {
          const classes = [className, navOpen ? activeClassName : ''].join(' ')

          return (
            <button
              className={classes}
              {...props}
              onClick={toggleNav.bind({}, target)}
              aria-expanded={navOpen[target]}
            >
              {label}
            </button>
          )
        }}
      </NavContext.Consumer>
    )
  }
}

export default MenuToggle
