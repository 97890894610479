import React, { createContext, useCallback, useState } from 'react'

export const TextColourContext = createContext({
  textColour: 'light',
  setTextColour: colour => {},
})

export const TextColourContextProvider = ({ children }) => {
  const [textColour, setTextColourState] = useState('light')

  const setTextColour = useCallback(
    (colour = 'light') => {
      if (!['light', 'dark'].includes(colour)) {
        throw new Error('Colour must be one of "light" or "dark"')
      }

      setTextColourState(original => colour)
    },
    [setTextColourState],
  )

  return (
    <TextColourContext.Provider value={{ textColour, setTextColour }}>
      {children}
    </TextColourContext.Provider>
  )
}

export default TextColourContextProvider
