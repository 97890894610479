import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import ReactPlayer from 'react-player'
import isInViewport from '../../utils/is-in-viewport'
import { Image } from '@superrb/gatsby-addons/components'
import { useEventListener, useIsInViewport } from '@superrb/gatsby-addons/hooks'

const PlayIcon = ({ image }) => (
  <div className="project-content__media-item__player-wrapper ">
    {image && (
      <Image
        image={image}
        alt={image.alt}
        sizes={image.sizes}
        className="project-content__media-item__player-background"
      />
    )}
    <div className="project-content__media-item__player-playbutton">
      <svg
        width="16"
        height="21"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 20V1L15 10.7714L1 20Z" stroke="#F7F4ED" />
      </svg>
      <span className="play-text button-swoop" data-title="Play Video">
        Play Video
      </span>
    </div>
  </div>
)

const ProjectVideo = ({ video, image }) => {
  const screenfull = useRef(null)
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [started, setStarted] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    setRef(player.current)
  }, [player.current])

  const togglePlaying = useCallback(() => {
    setPlaying(playing => !playing)

  }, [setPlaying])

  const toggleFullScreen = useCallback(() => {
    setFullScreen(fullScreen => !fullScreen)
  }, [setFullScreen])

  useLayoutEffect(() => {
    ;(async () => {
      const library = await import('screenfull')
      screenfull.current = library.default
    })()
  }, [screenfull])

  useEffect(() => {
    if (!screenfull.current) {
      return
    }

    if (player.current && fullScreen) {
      screenfull.current.request(player.current)

      return
    }

    screenfull.current.exit()
  }, [screenfull, fullScreen])

  useEffect(() => {
    if (!screenfull.current) {
      return
    }

    screenfull.current.on('change', () => {
      if (!screenfull.current.isFullscreen) {
        setFullScreen(false)
      }
    })
  }, [screenfull, setFullScreen])

  const handleScroll = useCallback(() => {
    if (player.current && isInViewport) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  })

  useEventListener(
    'scroll',
    handleScroll,
    { passive: true },
    typeof window !== 'undefined' ? window : undefined,
    isInViewport,
  )

  return (
    <figure className="project-content__media-item-video" ref={player}>
      {loaded && (
        <button
          className={`project-content__media-item-video-overlay ${
            playing ? 'playing' : ''
          }`}
          onClick={togglePlaying}
        >
          <span className="screenreader-text">
            {playing ? 'Pause Video' : 'Play Video'}
          </span>
          {!playing && <PlayIcon image={!started ? image : undefined} />}
        </button>
      )}

      {/*loaded && playing && screenfull?.current?.isEnabled && (
        <button
          className="project-content__media-item-video-fullscreen"
          onClick={toggleFullScreen}
        >
          <span className="screenreader-text">
            {fullScreen ? 'Exit Full Screen' : 'Full Screen'}
          </span>
          {fullScreen ? <Minimise /> : <Maximise />}
        </button>
      )*/}

      <ReactPlayer
        url={`${video.url}&background=1`}
        muted={true}
        playing={playing}
        controls={true}
        autoPlay={false}
        className="project-content__media-item__player"
        light={false}
        height="auto"
        playsinline
        playsInline
        width="100%"
        onReady={() => {
          setLoaded(true)
        }}
        onPlay={() => {
          setStarted(true)
        }}
        config={{
          vimeo: {
            playerOptions: {
              responsive: true,
            },
          },
        }}
        playIcon={<PlayIcon image={image} />}
      />
    </figure>
  )
}

export default ProjectVideo
