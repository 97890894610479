import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import { TextColourContext } from '../../context/text-colour-context-provider'
import { ScrollDown } from './scroll-down'
import VideoAutoplayBanner from './video-autoplay-banner'
import { useEventListener, useIsMobile } from '@superrb/gatsby-addons/hooks'

export const ProjectBanner = ({
  title,
  subtitle,
  mainImage,
  portraitImage,
  colour,
  autoPlayVideo,
}) => {
  const { textColour } = useContext(TextColourContext)
  const isMobile = useIsMobile(true)
  let image = mainImage

  const handleResize = useCallback(() => {
    const bannerContent = document.querySelector('.banner__content')
    const bannerHeight = document.querySelector('.banner')
    if (!bannerHeight) {
      return
    }
    if (bannerHeight.getBoundingClientRect().height > window.innerHeight) {
      bannerContent.style.height = window.innerHeight + 'px'
    }
  }, [])

  useEventListener(
    'resize',
    handleResize,
    {},
    typeof window !== 'undefined' ? window : undefined,
  )

  const classes = [
    'banner',
    textColour === 'light' ? 'banner--orange' : 'banner--white',
  ].join(' ')

  if (portraitImage.gatsbyImageData != null && isMobile) {
    image = portraitImage
  }

  return (
    <div className={classes}>
      <div className={`banner__image ${autoPlayVideo ? 'video' : ''}`}>
        {image && !autoPlayVideo && (
          <Image
            className="banner__image"
            image={image}
            alt={image.alt ? image.alt : title}
            preload={true}
          />
        )}
        {autoPlayVideo && <VideoAutoplayBanner url={autoPlayVideo} />}
      </div>

      <div className={`banner__content ${autoPlayVideo ? 'video' : ''}`}>
        {title && <h1 className="banner__title">{title}</h1>}
        {subtitle && <p className="banner__subtitle">{subtitle}</p>}
      </div>
      <ScrollDown
        target=".project-content"
        video={!!autoPlayVideo}
      />
    </div>
  )
}

export default ProjectBanner
